export const getCountUnities = (unities) => {
    if(unities.length > 0){
        return unities.length
    }
}
export const getMinAndMaxPrice = (unities) => {
    const prices = unities.map(unity => unity?.operations[0]?.prices[0]?.price)
    let min = 0
    let max = 0
    min = Math.min(...prices)
    max = Math.max(...prices)
    return {min, max}
}

export const findEnvironments = (unities) => {
    const array = []
    if(!unities){return array}
    if(unities?.length > 0){
      for(let i = 1; i <= 10; i++){
      const find = unities?.find(element => element?.room_amount === i)?.room_amount
      if(find){array.push(find)}
      }
    }
    return array
  }