import React,{useEffect, useState} from 'react'
import Development from '../development-card'
import Loading from '../Loading'
import { connect } from 'react-redux'
import {getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getReverseOrder } from '../../helpers/helper.developments'

const Developments = ({developmentsFilters,developments,loading,api_key,dispatch}) => {

    const defOff = [
        {"address":"Av. Beschtedt",
        "branch":{
        "address":"Humberto 913",
        "alternative_phone":"(+54 2323) 425913",
        "alternative_phone_area":"",
        "alternative_phone_country_code":"",
        "alternative_phone_extension":"",
        "branch_type":"Internal",
        "contact_time":"9 a 18hs",
        "created_date":"2019-07-22T12:55:30",
        "display_name":"Grupo Llanos",
        "email":"geremias@grupollanos.com.ar",
        "geo_lat":"-34.566056",
        "geo_long":"-59.11078999999999",
        "gm_location_type":"ROOFTOP",
        "id":14033,
        "is_default":true,
        "logo":"https:\/\/static.tokkobroker.com\/branch_logos\/14033\/logo-gll-color - Noelia Navarro.png",
        "name":"Grupo Llanos",
        "pdf_footer_text":"",
        "phone":"70787024",
        "phone_area":"011",
        "phone_country_code":"+54",
        "phone_extension":"",
        "use_pdf_footer":false
        },
        "construction_date":"2020-01-01",
        "construction_status":6,
        "custom_tags":[
        ],
        "description":"Everlinks, una forma de vivir.\n\nEverlinks Club de campo - Luj\u00e1n ofrece un medio ambiente natural con espacios verdes, lagunas, cancha de Golf, Club House, restaurante, salas de juego y eventos, pileta y canchas de tenis.\n\nNuestra naturaleza:\nEl dise\u00f1o y parquizaci\u00f3n de las 224 hect\u00e1reas fue realizado por el renombrado Carlos Thays, quien combin\u00f3 m\u00e1s de 15.000 \u00e1rboles, 23 lagunas y suaves desniveles que dan como resultado un paisaje extraordinario.\n\nEl Club House:\nEl emblem\u00e1tico edificio principal de 1.700 m2, dise\u00f1ado por Arq. Iv\u00e1n Robredo y decorado por Mar\u00eda Gim\u00e9nez y Victoria R\u00edos, cuenta con un restaurante y bar del hoyo 9 de primer nivel, grandes terrazas con vistas al golf, salas de lectura y salones para eventos.\n\nGolf:\nLa cancha de Golf de 7.500 yardas, es distinguida como una de las m\u00e1s largas de Sudam\u00e9rica y fue dise\u00f1ada por \u00c1ngel Reartes y construida bajo normas USGA. Cuenta con 4 tees de salida por hoyo, sistema de riego de \u00faltima generaci\u00f3n para fairways y greens, 23 lagunas, putting green de 18 hoyos y driving de 360 yardas.\n",
        "display_on_web":true,
        "excel_extra_headers":"[]",
        "fake_address":"Everlinks",
        "files":[
        {
        "file":"https:\/\/static.tokkobroker.com\/development_files\/535596604922356607234264514997941031503819174975024113506205\/MASTERPLAN.jpg"
        }
        ],
        "financing_details":"",
        "geo_lat":"-34.5391766",
        "geo_long":"-59.0814911",
        "id":24811,
        "is_starred_on_web":false,
        "location":{
        "divisions":[
        ],
        "full_location":"Argentina | G.B.A. Zona Oeste | Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "id":26083,
        "name":"Everlinks Golf & Country Club",
        "parent_division":"\/api\/v1\/location\/26075\/",
        "short_location":"Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "state":null,
        "weight":86
        },
        "name":"Everlinks Club de Campo - Luj\u00e1n",
        "photos":[
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "is_blueprint":false,
        "is_front_cover":true,
        "order":0,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/490816077763113470278652733078573158277929349811519156186591656216333946320_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":1,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/48608967130250907497522757012978906009078488053075348693961690585649105300438_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":2,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/67827197743533471520696203737629111073827853622497060970680049971793337971230_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":3,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/100159727308425560529198112760623877299884684013226114839978684492689055484922_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":4,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/2801386667150624976412696975231391338070232535228068277498810701508267999024_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":5,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/75730235922487840833643711271989655732217644171491537008173784778830075692168_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":6,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/115679809608666164431765608435836806314897859600270754169201251463214264034026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":7,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/46122429238422391435257129306382370695374272504890995492503814515972993967313_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":8,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/39394413418983264221308130210936403272594912746887123816766156300867396220335_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":9,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/89087092975558013247570271064531474362280053848565862301470262566719071837771_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":10,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/1886611243049341751472321210268816741823817539822695749563352516202751959490_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":11,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/18768134217741592998297740020267592616665724705412837626070906068524972807975_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":12,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/40289126887234051630385230083620936617988071896144605922116731613952831382159_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":13,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/57159883257983330646863447949231388314005358171485005550247855047347429413269_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":14,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/13344387550226311989218426427112500233358293106089155494349629025325216199026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":15,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/81706932715812475357311504970193886764318327347906474232587864832918195493347_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":16,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/76682271515044542413345989179845101656055306397607287775221394880590107032681_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":17,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/64614310119248452731163674225532849071186263068274421421735889029696996309913_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":18,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/90885164711780310184261342693964341755576530407784209500499779366645112280522_thumb.jpg"
        }
        ],
        "publication_title":"Barrio Privado - Everlinks Golf & Country Club",
        "reference_code":"GPN24811",
        "resource_uri":"\/api\/v1\/development\/24811\/",
        "tags":[
        {
        "id":3,
        "name":"Gas Natural",
        "type":1
        },
        {
        "id":4,
        "name":"Internet",
        "type":1
        },
        {
        "id":5,
        "name":"Electricidad",
        "type":1
        },
        {
        "id":6,
        "name":"Pavimento",
        "type":1
        },
        {
        "id":7,
        "name":"Telefono",
        "type":1
        },
        {
        "id":32,
        "name":"Centro de deportes",
        "type":3
        },
        {
        "id":36,
        "name":"Quincho",
        "type":3
        },
        {
        "id":41,
        "name":"SUM",
        "type":3
        },
        {
        "id":42,
        "name":"Seguridad",
        "type":3
        }
        ],
        "type":{
        "code":"PN",
        "id":4,
        "name":"Barrio Privado"
        },
        "users_in_charge":{
        "cellphone":"",
        "email":"geremias@grupollanos.com.ar",
        "id":25736,
        "name":"Gerem\u00edas Llanos",
        "phone":"( 54 2323) 425913",
        "picture":"https:\/\/static.tokkobroker.com\/static\/img\/user.png",
        "position":""
        },
        "videos":[
        ],
        "web_url":""},
        {"address":"Av. Beschtedt",
        "branch":{
        "address":"Humberto 913",
        "alternative_phone":"(+54 2323) 425913",
        "alternative_phone_area":"",
        "alternative_phone_country_code":"",
        "alternative_phone_extension":"",
        "branch_type":"Internal",
        "contact_time":"9 a 18hs",
        "created_date":"2019-07-22T12:55:30",
        "display_name":"Grupo Llanos",
        "email":"geremias@grupollanos.com.ar",
        "geo_lat":"-34.566056",
        "geo_long":"-59.11078999999999",
        "gm_location_type":"ROOFTOP",
        "id":14033,
        "is_default":true,
        "logo":"https:\/\/static.tokkobroker.com\/branch_logos\/14033\/logo-gll-color - Noelia Navarro.png",
        "name":"Grupo Llanos",
        "pdf_footer_text":"",
        "phone":"70787024",
        "phone_area":"011",
        "phone_country_code":"+54",
        "phone_extension":"",
        "use_pdf_footer":false
        },
        "construction_date":"2020-01-01",
        "construction_status":6,
        "custom_tags":[
        ],
        "description":"Everlinks, una forma de vivir.\n\nEverlinks Club de campo - Luj\u00e1n ofrece un medio ambiente natural con espacios verdes, lagunas, cancha de Golf, Club House, restaurante, salas de juego y eventos, pileta y canchas de tenis.\n\nNuestra naturaleza:\nEl dise\u00f1o y parquizaci\u00f3n de las 224 hect\u00e1reas fue realizado por el renombrado Carlos Thays, quien combin\u00f3 m\u00e1s de 15.000 \u00e1rboles, 23 lagunas y suaves desniveles que dan como resultado un paisaje extraordinario.\n\nEl Club House:\nEl emblem\u00e1tico edificio principal de 1.700 m2, dise\u00f1ado por Arq. Iv\u00e1n Robredo y decorado por Mar\u00eda Gim\u00e9nez y Victoria R\u00edos, cuenta con un restaurante y bar del hoyo 9 de primer nivel, grandes terrazas con vistas al golf, salas de lectura y salones para eventos.\n\nGolf:\nLa cancha de Golf de 7.500 yardas, es distinguida como una de las m\u00e1s largas de Sudam\u00e9rica y fue dise\u00f1ada por \u00c1ngel Reartes y construida bajo normas USGA. Cuenta con 4 tees de salida por hoyo, sistema de riego de \u00faltima generaci\u00f3n para fairways y greens, 23 lagunas, putting green de 18 hoyos y driving de 360 yardas.\n",
        "display_on_web":true,
        "excel_extra_headers":"[]",
        "fake_address":"Everlinks",
        "files":[
        {
        "file":"https:\/\/static.tokkobroker.com\/development_files\/535596604922356607234264514997941031503819174975024113506205\/MASTERPLAN.jpg"
        }
        ],
        "financing_details":"",
        "geo_lat":"-34.5391766",
        "geo_long":"-59.0814911",
        "id":24811,
        "is_starred_on_web":false,
        "location":{
        "divisions":[
        ],
        "full_location":"Argentina | G.B.A. Zona Oeste | Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "id":26083,
        "name":"Everlinks Golf & Country Club",
        "parent_division":"\/api\/v1\/location\/26075\/",
        "short_location":"Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "state":null,
        "weight":86
        },
        "name":"Everlinks Club de Campo - Luj\u00e1n",
        "photos":[
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "is_blueprint":false,
        "is_front_cover":true,
        "order":0,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/490816077763113470278652733078573158277929349811519156186591656216333946320_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":1,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/48608967130250907497522757012978906009078488053075348693961690585649105300438_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":2,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/67827197743533471520696203737629111073827853622497060970680049971793337971230_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":3,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/100159727308425560529198112760623877299884684013226114839978684492689055484922_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":4,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/2801386667150624976412696975231391338070232535228068277498810701508267999024_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":5,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/75730235922487840833643711271989655732217644171491537008173784778830075692168_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":6,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/115679809608666164431765608435836806314897859600270754169201251463214264034026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":7,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/46122429238422391435257129306382370695374272504890995492503814515972993967313_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":8,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/39394413418983264221308130210936403272594912746887123816766156300867396220335_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":9,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/89087092975558013247570271064531474362280053848565862301470262566719071837771_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":10,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/1886611243049341751472321210268816741823817539822695749563352516202751959490_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":11,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/18768134217741592998297740020267592616665724705412837626070906068524972807975_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":12,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/40289126887234051630385230083620936617988071896144605922116731613952831382159_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":13,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/57159883257983330646863447949231388314005358171485005550247855047347429413269_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":14,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/13344387550226311989218426427112500233358293106089155494349629025325216199026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":15,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/81706932715812475357311504970193886764318327347906474232587864832918195493347_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":16,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/76682271515044542413345989179845101656055306397607287775221394880590107032681_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":17,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/64614310119248452731163674225532849071186263068274421421735889029696996309913_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":18,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/90885164711780310184261342693964341755576530407784209500499779366645112280522_thumb.jpg"
        }
        ],
        "publication_title":"Barrio Privado - Everlinks Golf & Country Club",
        "reference_code":"GPN24811",
        "resource_uri":"\/api\/v1\/development\/24811\/",
        "tags":[
        {
        "id":3,
        "name":"Gas Natural",
        "type":1
        },
        {
        "id":4,
        "name":"Internet",
        "type":1
        },
        {
        "id":5,
        "name":"Electricidad",
        "type":1
        },
        {
        "id":6,
        "name":"Pavimento",
        "type":1
        },
        {
        "id":7,
        "name":"Telefono",
        "type":1
        },
        {
        "id":32,
        "name":"Centro de deportes",
        "type":3
        },
        {
        "id":36,
        "name":"Quincho",
        "type":3
        },
        {
        "id":41,
        "name":"SUM",
        "type":3
        },
        {
        "id":42,
        "name":"Seguridad",
        "type":3
        }
        ],
        "type":{
        "code":"PN",
        "id":4,
        "name":"Barrio Privado"
        },
        "users_in_charge":{
        "cellphone":"",
        "email":"geremias@grupollanos.com.ar",
        "id":25736,
        "name":"Gerem\u00edas Llanos",
        "phone":"( 54 2323) 425913",
        "picture":"https:\/\/static.tokkobroker.com\/static\/img\/user.png",
        "position":""
        },
        "videos":[
        ],
        "web_url":""},
        {"address":"Av. Beschtedt",
        "branch":{
        "address":"Humberto 913",
        "alternative_phone":"(+54 2323) 425913",
        "alternative_phone_area":"",
        "alternative_phone_country_code":"",
        "alternative_phone_extension":"",
        "branch_type":"Internal",
        "contact_time":"9 a 18hs",
        "created_date":"2019-07-22T12:55:30",
        "display_name":"Grupo Llanos",
        "email":"geremias@grupollanos.com.ar",
        "geo_lat":"-34.566056",
        "geo_long":"-59.11078999999999",
        "gm_location_type":"ROOFTOP",
        "id":14033,
        "is_default":true,
        "logo":"https:\/\/static.tokkobroker.com\/branch_logos\/14033\/logo-gll-color - Noelia Navarro.png",
        "name":"Grupo Llanos",
        "pdf_footer_text":"",
        "phone":"70787024",
        "phone_area":"011",
        "phone_country_code":"+54",
        "phone_extension":"",
        "use_pdf_footer":false
        },
        "construction_date":"2020-01-01",
        "construction_status":6,
        "custom_tags":[
        ],
        "description":"Everlinks, una forma de vivir.\n\nEverlinks Club de campo - Luj\u00e1n ofrece un medio ambiente natural con espacios verdes, lagunas, cancha de Golf, Club House, restaurante, salas de juego y eventos, pileta y canchas de tenis.\n\nNuestra naturaleza:\nEl dise\u00f1o y parquizaci\u00f3n de las 224 hect\u00e1reas fue realizado por el renombrado Carlos Thays, quien combin\u00f3 m\u00e1s de 15.000 \u00e1rboles, 23 lagunas y suaves desniveles que dan como resultado un paisaje extraordinario.\n\nEl Club House:\nEl emblem\u00e1tico edificio principal de 1.700 m2, dise\u00f1ado por Arq. Iv\u00e1n Robredo y decorado por Mar\u00eda Gim\u00e9nez y Victoria R\u00edos, cuenta con un restaurante y bar del hoyo 9 de primer nivel, grandes terrazas con vistas al golf, salas de lectura y salones para eventos.\n\nGolf:\nLa cancha de Golf de 7.500 yardas, es distinguida como una de las m\u00e1s largas de Sudam\u00e9rica y fue dise\u00f1ada por \u00c1ngel Reartes y construida bajo normas USGA. Cuenta con 4 tees de salida por hoyo, sistema de riego de \u00faltima generaci\u00f3n para fairways y greens, 23 lagunas, putting green de 18 hoyos y driving de 360 yardas.\n",
        "display_on_web":true,
        "excel_extra_headers":"[]",
        "fake_address":"Everlinks",
        "files":[
        {
        "file":"https:\/\/static.tokkobroker.com\/development_files\/535596604922356607234264514997941031503819174975024113506205\/MASTERPLAN.jpg"
        }
        ],
        "financing_details":"",
        "geo_lat":"-34.5391766",
        "geo_long":"-59.0814911",
        "id":24811,
        "is_starred_on_web":false,
        "location":{
        "divisions":[
        ],
        "full_location":"Argentina | G.B.A. Zona Oeste | Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "id":26083,
        "name":"Everlinks Golf & Country Club",
        "parent_division":"\/api\/v1\/location\/26075\/",
        "short_location":"Lujan | Countries\/B.Cerrado (Lujan) | Everlinks Golf & Country Club",
        "state":null,
        "weight":86
        },
        "name":"Everlinks Club de Campo - Luj\u00e1n",
        "photos":[
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "is_blueprint":false,
        "is_front_cover":true,
        "order":0,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/490816077763113470278652733078573158277929349811519156186591656216333946320.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/490816077763113470278652733078573158277929349811519156186591656216333946320_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":1,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/48608967130250907497522757012978906009078488053075348693961690585649105300438.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/48608967130250907497522757012978906009078488053075348693961690585649105300438_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":2,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/67827197743533471520696203737629111073827853622497060970680049971793337971230.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/67827197743533471520696203737629111073827853622497060970680049971793337971230_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":3,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/100159727308425560529198112760623877299884684013226114839978684492689055484922.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/100159727308425560529198112760623877299884684013226114839978684492689055484922_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":4,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/2801386667150624976412696975231391338070232535228068277498810701508267999024.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/2801386667150624976412696975231391338070232535228068277498810701508267999024_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":5,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/75730235922487840833643711271989655732217644171491537008173784778830075692168.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/75730235922487840833643711271989655732217644171491537008173784778830075692168_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":6,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/115679809608666164431765608435836806314897859600270754169201251463214264034026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/115679809608666164431765608435836806314897859600270754169201251463214264034026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":7,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/46122429238422391435257129306382370695374272504890995492503814515972993967313.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/46122429238422391435257129306382370695374272504890995492503814515972993967313_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":8,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/39394413418983264221308130210936403272594912746887123816766156300867396220335.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/39394413418983264221308130210936403272594912746887123816766156300867396220335_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":9,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/89087092975558013247570271064531474362280053848565862301470262566719071837771.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/89087092975558013247570271064531474362280053848565862301470262566719071837771_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":10,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/1886611243049341751472321210268816741823817539822695749563352516202751959490.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/1886611243049341751472321210268816741823817539822695749563352516202751959490_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":11,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/18768134217741592998297740020267592616665724705412837626070906068524972807975.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/18768134217741592998297740020267592616665724705412837626070906068524972807975_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":12,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/40289126887234051630385230083620936617988071896144605922116731613952831382159.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/40289126887234051630385230083620936617988071896144605922116731613952831382159_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":13,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/57159883257983330646863447949231388314005358171485005550247855047347429413269.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/57159883257983330646863447949231388314005358171485005550247855047347429413269_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":14,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/13344387550226311989218426427112500233358293106089155494349629025325216199026.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/13344387550226311989218426427112500233358293106089155494349629025325216199026_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":15,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/81706932715812475357311504970193886764318327347906474232587864832918195493347.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/81706932715812475357311504970193886764318327347906474232587864832918195493347_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":16,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/76682271515044542413345989179845101656055306397607287775221394880590107032681.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/76682271515044542413345989179845101656055306397607287775221394880590107032681_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":17,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/64614310119248452731163674225532849071186263068274421421735889029696996309913.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/64614310119248452731163674225532849071186263068274421421735889029696996309913_thumb.jpg"
        },
        {
        "description":null,
        "image":"https:\/\/static.tokkobroker.com\/dev_water_pics\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "is_blueprint":false,
        "is_front_cover":false,
        "order":18,
        "original":"https:\/\/static.tokkobroker.com\/dev_original_pictures\/90885164711780310184261342693964341755576530407784209500499779366645112280522.jpg",
        "thumb":"https:\/\/static.tokkobroker.com\/dev_thumbs\/90885164711780310184261342693964341755576530407784209500499779366645112280522_thumb.jpg"
        }
        ],
        "publication_title":"Barrio Privado - Everlinks Golf & Country Club",
        "reference_code":"GPN24811",
        "resource_uri":"\/api\/v1\/development\/24811\/",
        "tags":[
        {
        "id":3,
        "name":"Gas Natural",
        "type":1
        },
        {
        "id":4,
        "name":"Internet",
        "type":1
        },
        {
        "id":5,
        "name":"Electricidad",
        "type":1
        },
        {
        "id":6,
        "name":"Pavimento",
        "type":1
        },
        {
        "id":7,
        "name":"Telefono",
        "type":1
        },
        {
        "id":32,
        "name":"Centro de deportes",
        "type":3
        },
        {
        "id":36,
        "name":"Quincho",
        "type":3
        },
        {
        "id":41,
        "name":"SUM",
        "type":3
        },
        {
        "id":42,
        "name":"Seguridad",
        "type":3
        }
        ],
        "type":{
        "code":"PN",
        "id":4,
        "name":"Barrio Privado"
        },
        "users_in_charge":{
        "cellphone":"",
        "email":"geremias@grupollanos.com.ar",
        "id":25736,
        "name":"Gerem\u00edas Llanos",
        "phone":"( 54 2323) 425913",
        "picture":"https:\/\/static.tokkobroker.com\/static\/img\/user.png",
        "position":""
        },
        "videos":[
        ],
        "web_url":""},
    ]

    const [filter,setFilter] = useState(0)
    const [buttons,setButtos] = useState([
        {
            name:"VER TODOS",
            value:0,
        },
        {
            name:"EN POZO",
            value:3,
        },
        {
            name:"EN CONSTRUCCIÓN",
            value:4
        },
        {
            name:"TERMINADOS",
            value:6
        }

    ])

    useEffect(() => {
        // console.log(api_key)
        if(api_key){	
            dispatch(updateFiltersDevelopment(filter))
            dispatch(getDevelopmentsFilterAction()) // Get all developments
        }
    },[filter,api_key])

    const checkStatusInArray = (id) => {
        if(developments?.data?.objects.find(element => element.construction_status === id)){
            return true
        }
        return false;
    }

    return  (
        <section id="featured-devs" class="pt-lg-5">
            <div className={"container-fluid my-5 pb-lg-3 " + (developments?.data?.objects.length > 1 ? 'd-block' : "d-none")}>
                <div className={"d-flex flex-wrap justify-content-center " }>
                    {buttons.map((button,index) => (
                        (checkStatusInArray(button.value) || (button.value === 0 && developments?.data?.objects.length > 1)) &&
                        <button className={'button-filter mb-2 mb-lg-0 ' + (filter === button.value ? ' active ' : '')} onClick={() => setFilter(button.value)}>{button.name}</button>
                    ))}
                </div>
            </div>
            {
            developmentsFilters.data && !loading ?
                <div class="container-fluid">
                    <div class="row no-gutters mt-5 mt-lg-1" >
                    {console.log(developmentsFilters.data.objects.length % 2)}
                        {
                            getReverseOrder(developmentsFilters.data.objects).length > 0 
                            ?   getReverseOrder(developmentsFilters.data.objects).map((development,index) => (
                                    <div class={index + " mb-3 mb-lg-4 pb-lg-2 px-lg-3 " + 
                                        (developmentsFilters.data.objects.length === 1 
                                        ? 'col-lg-12 ' //Si es uno solo, 100%
                                        : ((developmentsFilters.data.objects.length < 9 && index === 0 && developmentsFilters.data.objects.length % 2 === 1)  
                                            ? 'col-lg-12 ' // Si impares menor a 9, el primero 100%
                                            : (developmentsFilters.data.objects.length < 9) 
                                                ? 'col-lg-6' //Si menores a 9, 50%
                                                :  (developmentsFilters.data.objects.length >= 9 && developmentsFilters.data.objects.length % 3 === 1 && index === 0)
                                                    ? 'col-lg-12' // Si es mayor o igual a 9, y sobra 1, 100% el primero
                                                    : (developmentsFilters.data.objects.length >= 9 && developmentsFilters.data.objects.length % 3 === 2 && (index === 0 || index === 1)
                                                        ? 'col-lg-6' // Si son 9 o mas, y sobran 2, los primeros 2, 50%  
                                                        : 'col-lg-4' // Si son 9 o mas, 33,3%
                                        )))}>
                                        <Development key={index} development={development} />
                                    </div>                  
                                ))
                            :   <h4 className="text-center">No hay emprendimientos {filter !== 0 && buttons.find(element => element.value === filter).name?.toLowerCase()}</h4>
                        }
                    </div>
                </div>
            :<Loading />
            }
        </section> 
    )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    developments:state.developments.developments,
    api_key: state.settings.keys.tokko

}),null)(Developments);