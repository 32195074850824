import React from "react";
import Developments from './developments'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

export default function About() {

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                name
                sections {
                    developments {
                        about {
                            title
                        }
                    }
                }
            }
        }`
    )
  return (
    <section class="development-about">
        <div class="container-fluid">
            <div class="row">
                <div className="col-lg-12">
                    <Breadcrumbs
                        props={[
                        { name: realEstate.name, route: "/", location: "" },
                        { name: "Emprendimientos", route:   "", location: ""},
                        ]}
                    />
                </div>
                <div class="col-lg-12">
                    <h1>
                        {realEstate.sections.developments.about.title}
                        {/* <!-- Nuestros procedimientos ofrecen resultados de la mejor calidad que garantizan el éxito de tu inversión. --> */}
                    </h1>
                </div>
            </div>
        </div>
        <Developments />
        {/* <?php include('components/development/developments-featured.php') ?> */}
    </section>
  )}
